const MYACCOUNT_BASE = '/';
export const MYACCOUNT_LIGHT                    = (process.env.GATSBY_MYACCOUNT_LIGHT || null) === '1' ? true : false
export const GGFX_ENV                           = process.env.GATSBY_STRAPI_GGFX_ENV || process.env.GATSBY_GGFX_ENV || `i.dev`
export const WEBSITE_URL                        = process.env.GATSBY_SITE_URL || process.env.GATSBY_WEBSITE_URL || null
export const DISPLAY_PERFORMANCE                = process.env.GATSBY_DISPLAY_PERFORMANCE || null
export const FRONTEND_SITE_URL                  = process.env.GATSBY_SITE_URL || process.env.GATSBY_FRONTEND_SITE_URL || null
export const MYACCOUNT_THEME                    = process.env.GATSBY_MYACCOUNT_THEME || null
export const RETURN_TOKEN                       = process.env.GATSBY_RETURN_TOKEN || null
export const API_URL                            = process.env.REACT_APP_API_URL || process.env.GATSBY_API_URL || null
export const API_PREFIX                         = process.env.GATSBY_API_PREFIX || ''
export const CURRENCY_CODE                      = process.env.GATSBY_CURRENCY_CODE || 'GBP';
export const TWOFACTOR_AUTH                     = (process.env.GATSBY_TWOFACTOR_AUTH || null) === '1' ? true : false
export const MYACCOUNT_DASHBOARD                = MYACCOUNT_BASE;
export const MYACCOUNT_INBOX                    = `${MYACCOUNT_BASE}inbox`;
export const MYACCOUNT_MYPROPERTY               = `${MYACCOUNT_BASE}my-property/`;
export const MYACCOUNT_MYPROPERTY_SELLING       = `${MYACCOUNT_BASE}my-property/selling/:propertyid`;
export const MYACCOUNT_MYPROPERTY_BUYING        = `${MYACCOUNT_BASE}my-property/buying/:propertyid`;
export const MYACCOUNT_MYPROPERTY_RENTING       = `${MYACCOUNT_BASE}my-property/renting/:propertyid`;
export const MYACCOUNT_MYPROPERTY_LETTING       = `${MYACCOUNT_BASE}my-property/letting/:propertyid`;
export const MYACCOUNT_MYPROPERTY_URLS          = `${MYACCOUNT_BASE}my-property/:persona/:pid`; // for above four urls
export const MYACCOUNT_LANDLAND                 = `${MYACCOUNT_BASE}my-property/landlord`;
export const MYACCOUNT_CALENDAR                 = `${MYACCOUNT_BASE}calendar`;
export const MYACCOUNT_PROFILE                  = `${MYACCOUNT_BASE}profile`;
export const MYACCOUNT_SAVED_SEARCH             = `${MYACCOUNT_BASE}my-property/#saved-search`;
export const MYACCOUNT_AUTHCALLBACK             = `${MYACCOUNT_BASE}connect/:provider/redirect`;
export const MYACCOUNT_LOGIN                    = `${MYACCOUNT_BASE}login`;
export const MYACCOUNT_SIGNUP                   = `${MYACCOUNT_BASE}signup`;
export const MYACCOUNT_FORGOT_PASSWORD          = `${MYACCOUNT_BASE}forgot-password`;
export const MYACCOUNT_RESET_PASSWORD           = `${MYACCOUNT_BASE}reset-password`;
export const MYACCOUNT_CREATE_PASSWORD          = `${MYACCOUNT_BASE}create-password`;
export const MYACCOUNT_2FACTOR_AUTH             = `${MYACCOUNT_BASE}2factor-auth`;
export const MYACCOUNT_UNSUBSCRIBE              = `${MYACCOUNT_BASE}unsubscribe/:token`;
export const MYACCOUNT_EMAIL_UNSUBSCRIBE        = `${MYACCOUNT_BASE}unsubscribe/:source/:token`;
export const MYACCOUNT_SEARCH_RESULT            = `${MYACCOUNT_BASE}properties/:search_type/*`;
export const MYACCOUNT_SEARCH_RESULT_SALES      = `${MYACCOUNT_BASE}properties/sales`;
export const MYACCOUNT_SEARCH_RESULT_LETTINGS   = `${MYACCOUNT_BASE}properties/lettings`;
export const MYACCOUNT_SEARCH_MAP_RESULT        = `${MYACCOUNT_BASE}properties/map/:search_type/*`;
export const MYACCOUNT_SEARCH_MAP_RESULT_SALES   = `${MYACCOUNT_BASE}properties/map/sales`;
export const MYACCOUNT_SEARCH_MAP_RESULT_LETTINGS = `${MYACCOUNT_BASE}properties/map/lettings`;
export const MYACCOUNT_PROPERTY_DETAIL          = `${MYACCOUNT_BASE}property/:slug`;
export const MYACCOUNT_NEWS_OVERVIEW            = `${MYACCOUNT_BASE}news`;
export const MYACCOUNT_NEWS_DETAILS             = `${MYACCOUNT_BASE}news/:slug`;
export const MYACCOUNT_CONFIRMATION             = `${MYACCOUNT_BASE}confirmation/:type/:code`;
export const MYACCOUNT_EMAIL_VERIFY             = `${MYACCOUNT_BASE}confirmation/email`;
export const MYACCOUNT_FAQ                      = `${MYACCOUNT_BASE}faq`;
